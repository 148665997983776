<template>
  <div class="basic">
    <Steps :current="step" :link="link" :mode="mode" :id="id"></Steps>
    <div class="container p-4 editCourse__container">
      <div class="row">
        <div class="col-12 col-md-8">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form class="row login__formContent" @submit.prevent="handleSubmit(save)">
              <div class="col-12 col-md-7">
                <label for="Username" class="form-label text-start d-inline-block w-100">課程標題</label>
                <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                  <div :class="classes">
                    <input
                      type="text"
                      name="username"
                      v-model="title"
                      class="form-control mb-1"
                      id="Username"
                      required
                      @change="change = true"
                    >
                    <span class="text-danger mt-1">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-12 col-md-5">
                <label class="form-label text-start d-inline-block w-100">課程分類</label>
                <select class="form-select" aria-label="courses__filter" v-model="category" @change="category == '' ? categoryError = true : categoryError = false">
                  <option disabled value>請選擇分類</option>
                  <option :value="item.id" v-for="item in categories" :key="item.id">{{ item.name }}</option>
                </select>
                <span class="invalid text-danger mt-1" v-if="categoryError">{{ categoryError }}</span>
              </div>
              <!-- 課程效期 -->
              <!-- <div class="col-12 mt-3">
                <label class="form-label text-start d-inline-block w-100">效期</label>
                <select class="form-select" aria-label="courses__filter">
                  <option disabled value>請選擇效期</option>
                  <option value="永久">永久</option>
                  <option value="一年">一年</option>
                  <option value="三年">三年</option>
                </select>
              </div> -->
              <div class="col-12 mt-3">
                <label for="teacher" class="form-label text-start d-inline-block w-100">授課教師</label>
                <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                  <div :class="classes">
                    <input
                      type="text"
                      name="teacher"
                      v-model="teacher"
                      class="form-control mb-1"
                      id="teacher"
                      required
                      @change="change = true"
                    >
                    <span class="text-danger mt-1">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-12 mt-3">
                <label for="desc" class="form-label text-start d-inline-block w-50">課程描述
                  <small style="font-size:.8rem;">（字數限制為4000字內）</small>
                </label>
                <span class="editCourse__addLink d-inline-block w-50 text-end" data-bs-toggle="modal" data-bs-target="#linkModal"><i class="bi bi-link-45deg"></i>連結</span>
                <ValidationProvider rules='required' v-slot='{ errors, classes }'>
                  <div :class="classes">
                    <textarea
                      v-model="desc"
                      rows="10"
                      name="desc"
                      class="form-control mb-1"
                      required
                    >
                    </textarea>
                    <span class="text-danger mt-1">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <!-- <quill-editor class="ql-editor"
                  v-model="desc"
                  ref="myQuillEditor"
                  :options="editorOption"
                >
                </quill-editor> -->
              </div>
              <div class="col-12 mt-3">
                <label for="tags" class="form-label text-start d-inline-block w-100">新增標籤</label>
                <div class="row">
                  <div class="col-9 col-md-10">
                    <input
                      @keypress.enter.prevent="handleKeydown"
                      type="text"
                      v-model.trim="tag"
                      class="form-control mb-1"
                      @change="change = true">
                  </div>
                  <div class="col-3 col-md-2 ps-0 ps-md-2">
                    <button type="button" class="btn btn-main-outline w-100" @click.prevent="handleKeydown">新增</button>
                  </div>
                </div>
                <div class="d-flex justify-content-start flex-wrap" style="min-height: 40px;">
                  <div v-for="(tag, i) in tags" :key="tag" class="badge rounded-pill editCourse__pill">
                    <span>#{{ tag }}</span>
                    <i class="far fa-times-circle ms-1" @click.prevent="deleteTag(i)"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 my-3 d-flex align-items-center">
                <label for="bookcover" class="editCourse__bookcover-btn pe-2 pe-md-0 d-flex justify-content-start"><span class="btn btn-main-outline mb-2 w-100" style="max-width: 20rem;">上傳書本圖片</span></label>
                <input type="file" name="bookcover" id="bookcover" class="d-none" ref="bookfiles" @change="getBookUploaded();change = true">
                <img src="../assets/images/coverImg.svg" width="160" alt="" ref="bookImg" v-if="!bookImg || bookImg == 'NotSet'">
                <img :src="bookImg" width="160" alt="" ref="bookImg" v-if="bookImg && bookImg!== 'NotSet'">
                <input type="hidden" v-model='bookImg'>
              </div>
              <div class="col-12 my-3 d-flex align-items-center" v-if="mode == 'edit'">
                <label for="sampleFile" class="editCourse__bookcover-btn pe-2 pe-md-0">
                  <span class="btn btn-main-outline w-100 d-flex align-items-center justify-content-center" style="max-width: 20rem;">
                    <img src="../assets/images/upload-icon.png" width="25" class="me-1">
                    <span>上傳範例檔案</span>
                  </span>
                  <div class="text-start w-100 mt-1">
                    <small style="font-size: .8rem;max-width: 20rem;">(檔案限制：大小不超過500MB的.zip或是.7z檔)</small>
                  </div>
                  
                </label>
                <input type="file" name="sampleFile" id="sampleFile" class="d-none" ref="sampleFile" @change="getSampleFileuploaded">
                <p class="me-2">{{sampleFile == null? '' : sampleFile}}</p>
                <p v-if="sampleFileLoading">上傳中...</p>
                <img src="../assets/images/file-upload.svg" width="50" class="me-1" v-if="sampleFile == null">
                <input type="hidden">
              </div>
              <div class="col-12 my-3 d-flex align-items-center" v-if="sampleFiles.length > 0 && mode == 'edit'">
                <h3>已上傳之範例檔案</h3>
                <div class="col-2 d-flex flex-column align-items-center mb-3 position-relative" v-for="(item, i) in sampleFiles" :key="i">
                  <img src="../assets/images/file-upload.svg" alt="" width="35">
                  <div class="d-flex flex-column">
                    <p class="mb-1">{{ item.fileName }}</p>
                    <a class="mb-2 text-muted" :href="item.link" target="_blank">檔案</a>
                  </div>
                  <i class="bi bi-x-circle position-absolute editCourse__deleteFile" @click.once="deleteFile(item.id)"></i>
                </div>
                
              </div>
              <button class="btn btn-main mt-5 w-50 d-none d-md-block" style="margin-left: 12px;" @click.prevent="save">{{ mode == 'launch'? '下一步' : '儲存' }}</button>
            </form>
          </ValidationObserver>
        </div>
        <div class="col-12 col-md-4 mt-4 mt-md-0">
          <label for="cover" class="w-100"><span class="btn btn-main-outline mb-2 w-100" style="max-width: 20rem;">上傳課程封面圖片</span></label>
          <input type="file" name="cover" id="cover" class="d-none" ref="files" @change="getUploaded();change = true">
          <input type="hidden" v-model='coverImg'>
          <div class="card courses__card mx-auto"
            style="max-width: 20rem;min-height: 300px;"
          >
            <div class="courses__cover" ref="img" v-if="!coverImg || coverImg == 'NotSet'">

            </div>
            <div class="courses__cover" ref="img" v-if="coverImg && coverImg !== 'NotSet'" :style="{ backgroundImage: `url(${coverImg})`}">

            </div>
            <div class="card-body text-start py-4">
              <h5 class="card-title courses__card-title fw-bold" style="min-height: 30px;">{{ title }}</h5>
              <h6 class="card-title text-muted" style="min-height: 24px;">{{ teacher }}</h6>
              <div class="d-flex align-items-end mb-2">
                <div class="courses__rating me-2">
                  <i class="fas fa-star courses__rating__star"></i>
                  <i class="fas fa-star courses__rating__star"></i>
                  <i class="fas fa-star courses__rating__star"></i>
                  <i class="fas fa-star courses__rating__star"></i>
                  <i class="fas fa-star courses__rating__star"></i>
                </div>
                <span class="card-subtitle text-muted d-inline-block"><small>0</small> 則評價</span>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="card-subtitle text-muted"><small>0</small> 位學生</span>
                <strong class="text-right courses__card-price">NT$ <span>{{ price }}</span></strong>
              </div>
            </div>
          </div>
          <div class="form-check mt-4 text-start mx-auto" style="max-width: 20rem;">
            <input class="form-check-input" type="checkbox" id="setSelected" v-model="isSelected" @change="change = true">
            <label class="form-check-label" for="setSelected" style="line-height: 1.5rem;">
              設為<strong class="editCourse__strong ms-1">精選課程</strong>
            </label>
          </div>

          <form class="form-check mt-4 text-start mx-auto ps-0" style="max-width: 20rem;">
            <label for="file" class="form-label">公開預覽影片</label>
            <input type="text" placeholder="https://player.vimeo.com/video/"
              class="editChapter__input mb-2"
              v-model="vimeoUrl"
              readonly
              style="font-size: .9rem;" />
            <input class="form-control " type="file" id="file" name="file_data" ref="videoFile" @change="testCreateVideo()">
          </form>
          <small style="font-size: .8rem; font-weight: normal;">(影片檔案格式：MP4, MOV, WMV, AVI, FLV)</small>
          <span v-if="vimeoEndPoint" class="mt-2 d-block">上傳中</span>
          <span v-if="uploadingVideo" class="mt-2 d-block">{{ percentage }}</span>
          <!-- <span v-if="vimeoUrl === ''" class="invalid text-danger mt-1">請上傳影片</span> -->
        </div>

        <button class="btn btn-main mt-3 w-50 d-block d-md-none" style="margin-left: 12px;" @click.prevent="save">{{ mode == 'launch'? '下一步' : '儲存' }}</button>
        <a class="editCourse__back mt-3 w-50 text-start" v-if="mode == 'edit'" @click.prevent="backToMyCourse">
          返回至我的課程頁<i class="fas fa-chevron-right ms-1"></i>
        </a>
      </div>
      <div class="modal fade" id="linkModal" tabindex="-1" aria-labelledby="linkModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="addedLink='';addedLinkText='';linkString=''"></button>
            </div>
            <div class="modal-body d-flex flex-column text-start">
              <p class="mb-2">連結</p>
              <input type="text" class="form-control mb-2" v-model="addedLink" placeholder="https://...">
              <p class="mb-2">連結文字</p>
              <input type="text" class="form-control mb-2" v-model="addedLinkText" placeholder="網路書店">
              <button type="button" @click.prevent="linkify()" class="btn btn-primary mb-2">生成</button>
              <div class="d-flex justify-content-around mb-2">
                <input type="text" class="form-control" v-model="linkString" style="width: 80%">
                <button class="btn btn-main w-20" @click.prevent="copyUrl" style="width: 15%">複製</button>
              </div>
              <span>將生成的字串貼入描述中即可。</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Steps from '../components/Steps';
import * as tus from 'tus-js-client';

const Swal = require('sweetalert2');

export default {
  name: 'EditCourse',
  components: {
    Steps,
  },
  data() {
    return {
      title: '',
      step: 1,
      link: '/dashboard/edit_course/basic',
      category: '',
      categories: [],
      desc: '',
      bookImg: '',
      coverImg: '',
      mode: 'launch',
      goNext: false,
      tag: '',
      tags: [],
      localCourse: {},
      isSelected: false,
      categoryError: '',
      id: '',
      editData: '',
      teacher: '',
      change: false,
      metaTitle: '編輯課程 - 元學堂',
      uploadLink: '',
      addedLink: '',
      addedLinkText: '',
      linkString: '',
      tempVideoId: 0,
      vimeoUrl: '',
      percentage: 0,
      uploadingVideo: false, 
      vimeoEndPoint: false,
      price: 0,
      sampleFile: null,
      sampleFileLoading: false,
      sampleFiles: [],
    };
  },
  metaInfo: {
    title: "編輯課程 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '編輯課程 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    decodeEntity(inputStr) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = inputStr;
      return textarea.value;
    },
    save() {
      const vm = this;
      vm.goNext = true;
      if (vm.category == '') {
        vm.categoryError = '請選擇課程分類';
      }
      if(vm.vimeoUrl == '') {
        return ;
      }
      let course;
      if (vm.title && vm.desc && vm.category) {
        course = {
          courseName: vm.title,
          courseDescription: vm.desc,
          courseCategoryId: vm.category,
          coursePrevImgUrl: vm.coverImg == 'NotSet' ? '' : vm.coverImg,
          coursePrevImgUrl1: vm.bookImg == 'NotSet' ? '' : vm.bookImg,
          selected: vm.isSelected,
          courseHashtag: vm.tags,
          authorName: vm.teacher,
          trailVideoId: vm.tempVideoId,
          trailerVideoUrl: vm.vimeoUrl
        };

        if (vm.mode == 'edit') {
          vm.$log.debug({ ...vm.course, ...course });
          const data = { ...vm.course, ...course };
          vm.$http.put(`${this.$API_PATH}/Course`, data, {
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
              Authorization: `bearer ${vm.userAuth}`,
            },
          }).then((res) => {
            vm.goNext = true;
            Swal.fire(
              '儲存成功',
              `${res.data.message}`,
              'success',
            );
          }).catch((err) => {
            vm.$log.debug(err.response);
            if (err.response) {
              Swal.fire(
                '',
                `${err.response.data.message}`,
                'info',
              );
            } else {
              Swal.fire(
                '錯誤',
                '請稍候再試',
                'info',
              );
            }
          });
        } else {
          // localStorage.setItem('Course', JSON.stringify(course));
          this.$store.dispatch('courseModule/setCourseData', course);
          vm.$router.push('/dashboard/edit_course/syllabus');
        }
      }

      vm.$log.debug({ ...course, ...vm.editData });
    },
    getCategories() {
      this.$http.get(`${this.$API_PATH}/Course/Categories`, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.categories = res.data.data.courseCategories;
        this.$log.debug(this.categories);
      }).catch((err) => {
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    getUploaded(e) {
      // this.$refs.img.style.backgroundImage = `url(${URL.createObjectURL(this.$refs.files.files[0])})`;
      const uploadedFile = this.$refs.files.files[0];
      this.$log.debug(uploadedFile);
      const vm = this;
      const formData = new FormData();
      formData.append('upload', uploadedFile);
      // https://imgupload.metalearning.com.tw
      this.$http.post(`${vm.$API_IMGPATH}/Course`, formData)
        .then((response) => {
          vm.$log.debug(response.data);
          vm.coverImg = response.data.url;
          vm.$refs.img.style.backgroundImage = `url(${response.data.url})`;
        }).catch((err) => {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          );
          vm.$log.debug(err.response);
        });
    },
    getBookUploaded() {
      // this.$refs.img.style.backgroundImage = `url(${URL.createObjectURL(this.$refs.files.files[0])})`;
      const uploadedFile = this.$refs.bookfiles.files[0];
      // console.log(uploadedFile);
      const vm = this;
      const formData = new FormData();
      formData.append('upload', uploadedFile);
      this.$http.post(`${vm.$API_IMGPATH}/CourseBook`, formData)
        .then((response) => {
          vm.$log.debug(response.data);
          vm.bookImg = response.data.url;
          vm.$refs.bookImg.src = `${response.data.url}`;
        }).catch((err) => {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          );
          vm.$log.debug(err.response);
        });
    },
    handleKeydown() {
      if (!this.tags.includes(this.tag) && this.tag) {
        this.tag = this.tag.replace(/\s/g, '');
        this.tags.push(this.tag);
      }
      this.tag = '';
    },
    deleteTag(i) {
      this.tags.splice(i, 1);
    },
    checkLocal() {
      // this.localCourse = JSON.parse(localStorage.getItem('Course'));
      if (this.course.courseCategoryId !== -1) {
        this.title = this.course.courseName;
        this.desc = this.course.courseDescription;
        this.category = this.course.courseCategoryId;
        this.desc = this.decodeEntity(this.course.courseDescription);
        this.coverImg = this.course.coursePrevImgUrl;
        this.bookImg = this.course.coursePrevImgUrl1;
        this.teacher = this.course.authorName;
        this.isSelected = this.course.selected;
        this.tags = this.course.courseHashtag;
        this.tempVideoId = this.course.trailVideoId;
        this.vimeoUrl = this.course.trailerVideoUrl;
        this.price = this.course.price;
      }
    },
    getEditData() {
      this.$http.get(`${this.$API_PATH}/Course?CourseId=${this.id}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        if (res.data.data) {
          this.editData = res.data.data;
          this.$store.dispatch('courseModule/getEditData', res.data.data);
          this.$store.dispatch('courseModule/setCourseData', { trailVideoId: res.data.data.trailerVideoId });
          this.title = this.course.courseName;
          this.desc = this.course.courseDescription;
          this.category = this.course.courseCategoryId;
          this.desc = this.decodeEntity(this.course.courseDescription);
          this.coverImg = this.course.coursePrevImgUrl;
          this.bookImg = this.course.coursePrevImgUrl1;
          this.teacher = this.course.authorName;
          this.isSelected = this.course.selected;
          this.tags = this.course.courseHashtag;
          this.tempVideoId = this.course.trailVideoId;
          this.vimeoUrl = this.course.trailerVideoUrl;
          this.price = this.course.price;
        }
      }).catch((err) => {
        this.goNext = true;
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    backToMyCourse() {
      this.$router.push('/dashboard/courses');
    },
    linkify() {
      let replacedText;
      if(this.addedLinkText !== '') {
        replacedText = `<a href="${this.addedLink}" target="_blank">${this.addedLinkText}</a>`;
      } else {
        replacedText = `<a href="${this.addedLink}" target="_blank">${this.addedLink}</a>`;
      }
      this.linkString = replacedText;
    },
    testCreateVideo() {
      const vm = this;
      const uploadedFile = this.$refs.videoFile.files[0];
      this.vimeoEndPoint = true;
      if (!this.$refs.videoFile.files[0]) {
        vm.vimeoEndPoint = false;
        window.alert('請上傳mp4影片檔案');
        return;
      } else if (!uploadedFile['type'].includes('video')) {
        vm.vimeoEndPoint = false;
        window.alert('請上傳mp4影片檔案');
        return;
      }
      vm.$log.debug(uploadedFile);

      this.$http.post(`${this.$API_PATH}/Vimeo`, {
        VideoSize: uploadedFile.size,
      }, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        vm.$log.debug(res.data);
        vm.uploadLink = res.data.data.uploadLink;
        vm.vimeoUrl = res.data.data.videoEmbedUrl;
        vm.tempVideoId = res.data.data.id;
        vm.uploadingVideo = true;
        var upload = new tus.Upload(uploadedFile, {
          uploadUrl: this.uploadLink,
          storeFingerprintForResuming: true,
          fingerprint(file, options) {
            const value = this.uploadLink;
            return Promise.resolve(value);
          },
          onError(error) {
            vm.$log.debug(`Failed because: ${error}`);
            vm.uploadingVideo = false;
            vm.vimeoEndPoint = false;
            Swal.fire(
              '',
              '上傳出現錯誤，請再試一次',
              'info',
            );
          },
          onProgress(bytesUploaded, bytesTotal) {
            vm.percentage = `${(bytesUploaded / bytesTotal * 100).toFixed(0)}%`;
          },
          onSuccess() {
            vm.$log.debug('Download %s from %s', upload.file.name, upload);
            vm.uploadingVideo = false;
            vm.vimeoEndPoint = false;
          },
        });
        // Start the upload
        upload.start();
      }).catch((err) => {
        vm.$log.debug(err.response);
        vm.vimeoEndPoint = false;
        //this.readyUpload = false;
        window.alert('錯誤，請稍後再試。');
      });
    },
    copyUrl() {
      navigator.clipboard.writeText(this.linkString)
        .then(() => {
          console.log('copy');
        })
    },
    getSampleFileuploaded() {
      const uploadedFile = this.$refs.sampleFile.files[0];
      this.sampleFile = this.$refs.sampleFile.files[0].name;
      console.log(uploadedFile);
      if(uploadedFile) {
        const type = uploadedFile.name.split('.').pop();
        if(!['7z', 'zip', 'Zip'].includes(type)) {
          Swal.fire(
            '',
            `可上傳之檔案類型: .zip, .7z`,
            'info',
          );
          return;
        }
        if(uploadedFile.size > 524288000) {
          Swal.fire(
            '',
            `檔案大小不得超過500MB`,
            'info',
          );
          return
        }
      }
      
      const vm = this;
      const formData = new FormData();
      this.sampleFileLoading = true;
      formData.append('file', uploadedFile);
      
      this.$http.post(`${vm.$API_PATH}/Course/FileUpload/${vm.id}`, '', {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${vm.userAuth}`,
        },
      }).then((response) => {
          vm.$log.debug(response.data.message);
          return vm.$http.post(`${vm.$API_PATH}/Course/FileUpload`, formData, {
            headers: {
              Accept: '*/*',
              'Content-Type': 'multipart/form-data',
              Authorization: `bearer ${vm.userAuth}`,
            },
          })
        }).then((res) => {
          this.sampleFileLoading = false;
          this.getFilesUpload();
        })
        .catch((err) => {
          vm.sampleFileLoading = false;
          if (err.response) {
              Swal.fire(
                '',
                `${err.response.data.message}`,
                'info',
              );
            } else {
              Swal.fire(
                '',
                '錯誤，請稍後再試',
                'info',
              );
            }
          vm.$log.debug(err.response);
        });
    },
    getFilesUpload() {
      this.$http.get(`${this.$API_PATH}/Course/FileUpload/${this.id}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        if (res.data.data) {
          this.sampleFiles = res.data.data.files;
        }
      }).catch((err) => {
        if (err.response) {
          Swal.fire(
            '',
            `${err.response.data.message}`,
            'info',
          );
        } else {
          Swal.fire(
            '',
            '錯誤，請稍後再試',
            'info',
          );
        }
      vm.$log.debug(err.response);
      });
    },
    deleteFile(fid) {
      this.updateLoading(true);
      this.$http.delete(`${this.$API_PATH}/Course/FileUpload/${fid}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.updateLoading(false);
        if (res.data) {
          Swal.fire(
            '',
            `${res.data.message}`,
            'success',
          );
        }
        this.getFilesUpload();
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          Swal.fire(
            '',
            `${err.response.data.message}`,
            'info',
          );
        } else {
          Swal.fire(
            '',
            '錯誤，請稍後再試',
            'info',
          );
        }
      });
    },
    /* testFunction() {
      let range;
      let arr = this.desc.split(window.getSelection().toString());
      if(window.getSelection) {
        if(window.getSelection().rangeCount) {
          range = window.getSelection().getRangeAt(0);
          console.log(range);
          range.deleteContents();
          range.insertNode(document.createTextNode('test'));
        }
      }
    }, */
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
    ...mapGetters('courseModule', ['course']),
  },
  beforeRouteLeave(to, from, next) {
    const vm = this;
    if (vm.goNext || vm.change == false) {
      next();
    } else {
      let text = '';
      if (this.mode == 'edit') {
        text = '尚未儲存這次的更新，請按儲存以儲存您的修改。';
      } else {
        text = '提醒您，上架課程將不會暫存您的變更，需完成至第四步驟完成發布，本系統即會儲存並發布您的課程。';
      }

      Swal.fire({
        title: '',
        text,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '取消',
        confirmButtonText: '離開',
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        }
      });
    }
  },
  mounted() {
    window.addEventListener('beforeunload', (e) => {
      const confirmationMessage = '你還沒有完成你的文章，就這樣離開了嗎？';

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Webkit, Safari, Chrome
    });
  },
  destroyed() {
    window.removeEventListener('beforeunload', (e) => {
      const confirmationMessage = '你還沒有完成你的文章，就這樣離開了嗎？';

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Webkit, Safari, Chrome
    });
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    this.getCategories();
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.mode = 'edit';
      this.getEditData();
      this.getFilesUpload();
    } else {
      this.checkLocal();
      this.mode = 'launch';
      localStorage.setItem('step', '1');
    }
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }

  .invalid.text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  ::v-deep .quill-editor {
    padding: 12px 0px;
    height: 200px;
  }

</style>
